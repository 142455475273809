<template>
  <div class="text-bb-text-default px-6 py-4 bg-white rounded-lg bb-default-shadow border border-neutral-50 w-full">
    <div class="flex items-center justify-between">
      <div class="flex items-center gap-4">
        <div class="h-10 w-10 rounded-lg bg-neutral-0 text-bb-brand-purple">
          <ic-donut-chart class="my-auto mx-auto h-full" />
        </div>
        <h5 class="h5 text-bb-text-default">Top of Search</h5>
      </div>

      <div class="flex items-center gap-4">
        <div
          v-if="showResetIcon"
          @click="resetChartData()"
        >
          <ic-reset class="text-bb-brand-purple cursor-pointer" />
        </div>
        <new-tooltip direction="bottom-end">
          <ic-info class="text-bb-disabled-buttons" />
          <template #content>
            <div class="w-48">
              <p>This chart displays #1 paid ranking share for your brand keywords.</p>
            </div>
          </template>
        </new-tooltip>
      </div>
    </div>
    <div v-if="noChartData">
      <no-chart-data class="py-10" />
    </div>
    <div
      v-else
      class="py-4"
    >
      <doughnut-chart
        ref="chart"
        chart-id="top-of-page"
        :chart-data="chartData"
        @update-selected-legend-count="updateSelectedLegendCount"
      />
    </div>
  </div>
</template>

<script>
import IcInfo from '@/components/icon/ic-info.vue'
import IcDonutChart from '@/components/icon/brightbid/ic-donut-chart.vue'
import IcReset from '@/components/icon/ic-reset.vue'
import NewTooltip from '@/components/alert/NewTooltip.vue'
import DoughnutChart from '@/views/site/search/competitor_monitoring_v2/overview/DoughnutChart.vue'
import NoChartData from '@/views/site/search/competitor_monitoring_v2/overview/cards/NoChartData.vue'

export default {
  name: 'TopOfPageChart',
  components: { IcDonutChart, IcInfo, NewTooltip, IcReset, DoughnutChart, NoChartData },
  props: {
    competitionRanking: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showResetIcon: false,
    }
  },
  computed: {
    noChartData() {
      return this.competitionRanking.length === 0 || !this.competitionRanking
    },
    chartData() {
      return this.competitionRanking.map(item => {
        return {
          label: item.competitorName,
          percentage: item.topPagePercentage,
          isOwn: item.isOwn,
        }
      })
    },
  },
  methods: {
    resetChartData() {
      this.$refs.chart.resetChartData()
    },
    updateSelectedLegendCount(count) {
      this.showResetIcon = count > 0
    },
  },
}
</script>

<style scoped lang="scss"></style>
