<template>
  <div
    class="overflow-hidden text-bb-text-default bg-white rounded-lg bb-default-shadow border border-neutral-50 w-full h-120 divide-y divide-neutral-100"
  >
    <div v-if="noChartData">
      <div class="flex items-center gap-4 px-6 py-4">
        <div class="h-10 w-10 rounded-lg bg-neutral-0 text-bb-brand-purple">
          <ic-rank class="text-bb-brand-purple my-auto mx-auto h-full" />
        </div>
        <h5 class="h5 text-bb-text-default">Top Competitors</h5>
      </div>
      <div>
        <no-chart-data class="py-5" />
      </div>
    </div>
    <div v-else>
      <div class="flex items-center gap-4 p-4 pb-0">
        <div class="h-10 w-10 rounded-lg bg-neutral-0 text-bb-brand-purple">
          <ic-rank class="text-bb-brand-purple my-auto mx-auto h-full" />
        </div>
        <h5 class="h5 text-bb-text-default">Top Competitors</h5>
      </div>

      <div class="grid grid-cols-12 px-6 py-4">
        <div class="col-span-4 my-auto">
          <p class="text-sm font-medium text-bb-text-default">Competitor Name</p>
        </div>
        <div class="col-span-3 my-auto">
          <p class="text-sm font-medium text-bb-text-default text-center">Impression Share</p>
        </div>
        <div class="col-span-3 my-auto">
          <p class="text-sm font-medium text-bb-text-default text-center">Position above you</p>
        </div>
        <div class="col-span-2 text-center my-auto">
          <p class="text-sm font-medium text-bb-text-default">Ad Position</p>
        </div>
      </div>
      <div class="overflow-y-auto max-h-80 base-scrollbar">
        <div
          v-for="(competitor, index) in topCompetitors"
          :key="competitor.competitorName"
          class="hover:bg-bb-background-purple-0 hover:border-bb-brand-purple border-white border-l-4 duration-300 ease-linear"
        >
          <div class="grid grid-cols-12 px-4 h-16">
            <div class="col-span-4">
              <div class="flex items-center gap-4 my-auto h-full">
                <p class="font-semibold text-sm text-black">{{ index + 1 }}</p>
                <div>
                  <p class="font-medium text-sm text-black">{{ competitor.competitorName }}</p>
                  <p class="text-xs text-bb-text-secondary">www.{{ competitor.competitorName }}.com</p>
                </div>
              </div>
            </div>
            <div class="col-span-3 my-auto text-center flex justify-center gap-2">
              <progress-bar
                :value="competitor.impressionShare"
                :progress-mark="101"
                :show-percent-label="false"
                class="pl-4"
                use-solid-color
                container-width="w-15"
                container-height="12px"
              />
              <p class="text-sm font-medium pr-2">
                {{ competitor.impressionShare ? `${competitor.impressionShare}%` : '-' }}
              </p>
            </div>
            <div class="col-span-3 my-auto mx-auto">
              <div class="col-span-3 my-auto text-center">
                <p class="text-sm font-medium pr-2">
                  {{ competitor.aboveMePercentage ? `${competitor.aboveMePercentage}%` : '-' }}
                </p>
              </div>
            </div>

            <div class="col-span-2 my-auto mx-auto text-center pr-1">
              <div :id="`bar-chart-${competitor.competitorName}-${index}`"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="h-16 flex items-center gap-3 px-6 text-bb-brand-purple">
        <div
          class="flex items-center cursor-pointer"
          @click="$emit('see-all-competitors')"
        >
          <p class="text-sm font-medium">See all competitors</p>
          <ic-chevron
            direction="right"
            :size="20"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IcRank from '@/components/icon/brightbid/ic-rank.vue'
import { createRankSvg } from '@/views/site/search/competitor_monitoring_v2/overview/create-rank'
import NoChartData from '@/views/site/search/competitor_monitoring_v2/overview/cards/NoChartData.vue'
import ProgressBar from '@/components/shared/ProgressBar.vue'
import IcChevron from '@/components/icon/ic-chevron.vue'

export default {
  name: 'TopCompetitors',
  components: { IcChevron, NoChartData, ProgressBar, IcRank },
  props: {
    topCompetitors: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      smallChart: null,
      competitorRankChart: [],
    }
  },
  computed: {
    noChartData() {
      return this.topCompetitors.length === 0 || !this.topCompetitors
    },
  },
  watch: {
    topCompetitors: {
      handler(newVal) {
        this.$nextTick(() => this.createHistogram(newVal))
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    createHistogram(data) {
      const competitionCharts = data.map(competitor => {
        return {
          competitor: competitor.competitorName,
          ranks: [
            {
              rank: 1,
              count: parseFloat(competitor?.position_1 ?? 0),
              percentage: parseFloat(competitor?.position_1 ?? 0),
            },
            {
              rank: 2,
              count: parseFloat(competitor?.position_2 ?? 0),
              percentage: parseFloat(competitor?.position_2 ?? 0),
            },
            {
              rank: 3,
              count: parseFloat(competitor?.position_3 ?? 0),
              percentage: parseFloat(competitor?.position_3 ?? 0),
            },
            {
              rank: 4,
              count: parseFloat(competitor?.position_4 ?? 0),
              percentage: parseFloat(competitor?.position_4 ?? 0),
            },
          ],
        }
      })
      competitionCharts.map((chart, index) => {
        const id = `#bar-chart-${chart.competitor}-${index}`
        createRankSvg(id, chart.ranks)
      })
    },
  },
}
</script>

<style scoped lang="scss"></style>
