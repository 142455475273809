<template>
  <div class="w-full h-full p-6 pb-20 space-y-6 relative responsive-section">
    <h3 class="py-2">Overview</h3>

    <div
      v-if="false"
      class="flex h-full items-center justify-center absolute top-0 mx-auto w-full inset-0 bg-white z-50 bg-opacity-75 transition-opacity"
    >
      <brightbid-loader />
    </div>

    <!--ROW 1-->
    <div class="flex flex-col lg:flex-row gap-6">
      <!--YOUR SUMMARY-->
      <summary-card
        title="Your Summary"
        :summary="mySummary"
      >
        <template #icon>
          <div class="bg-neutral-0 h-8 w-8 rounded-lg text-bb-brand-purple flex items-center justify-center">
            <ic-one-line-chart />
          </div>
        </template>
        <template #summary="{ summary }">
          <div>
            <div class="flex items-center gap-2">
              <p>{{ summary.label }}</p>
            </div>

            <h2 class="h1a my-2">{{ summary.value }}</h2>
          </div>
        </template>
      </summary-card>

      <!--COMPETITOR SUMMARY-->
      <summary-card
        title="Competitor Summary"
        :summary="summaryData"
      >
        <template #icon>
          <div class="bg-neutral-0 h-8 w-8 rounded-lg text-bb-brand-purple flex items-center justify-center">
            <ic-two-line-chart />
          </div>
        </template>
        <template #summary="{ summary }">
          <div class="text-center">
            <div class="flex items-center gap-2">
              <div
                class="rounded-full flex items-center justify-center h-8 w-8"
                :class="summary.iconClass"
              >
                <div>
                  <component :is="summary.icon" />
                </div>
              </div>
              <p>{{ summary.label }}</p>
            </div>

            <h2 class="h1a my-2">{{ summary.value }}</h2>
          </div>
        </template>
      </summary-card>
    </div>

    <!--ROW 2-->
    <div class="flex gap-6 w-full">
      <div class="w-full">
        <top-competitors
          :top-competitors="top10CompetitorsByImpressionShare"
          @see-all-competitors="seeAllCompetitors"
        />
      </div>
    </div>

    <!--ROW 3-->
    <div class="flex flex-col lg:flex-row gap-6 w-full">
      <div
        v-if="selectedMonitoringGroup?.type !== MONITORING_GROUP_TYPE.GENERIC"
        class="w-1/2 flex flex-col justify-between"
      >
        <notification-card
          :class="activatedSmartSavings === 0 ? 'bg-white text-bb-text-default' : 'purple-gradient text-white'"
          class="h-40 border border-neutral-50"
          card-class="border-bb-brand-purple p-5"
          card-title="Smart Activation"
        >
          <template #card-icon>
            <div class="text-bb-brand-purple bg-bb-secondary-purple rounded-full p-1.5">
              <ic-magic-wand :size="24" />
            </div>
          </template>
          <template #card-content>
            <div
              v-if="activatedSmartSavings === 0"
              class="space-y-2"
            >
              <p>Automatically pause your keywords when competition is low.</p>
              <MergeButtonRound
                button-type="brand-purple"
                @click="openSmartActivationModal"
              >
                <div class="flex items-center gap-2 justify-center mx-auto">
                  <div>
                    <ic-magic-wand />
                  </div>
                  <p class="w-40">Enable Smart Activation</p>
                </div>
              </MergeButtonRound>
            </div>
            <div
              v-else
              class="text-sm space-y-1"
            >
              <p>You have saved</p>
              <div class="flex items-center gap-4">
                <div class="flex items-center gap-1">
                  <ic-clock :size="16" />
                  <h4 class="h4">{{ totalHoursSaved }} Hours</h4>
                </div>
                <div class="flex items-center gap-1">
                  <ic-dollar :size="16" />
                  <h4 class="h4">{{ totalMoneySaved }}</h4>
                </div>
              </div>
              <p>Enabled on 1 campaign ({{ activatedSmartSavings }} keywords)</p>
            </div>
          </template>
          <template
            v-if="activatedSmartSavings > 0"
            #right-icon
          >
            <ic-solid-cog
              class="cursor-pointer absolute top-0 right-0"
              @click="openSmartActivationModal"
            />
          </template>
        </notification-card>
        <div @click="$router.push({ name: 'infringements-detector-v2' })">
          <notification-card
            class="h-40 border border-neutral-50 cursor-pointer"
            card-class="border-bb-red-error p-5"
            card-title="Infringements"
          >
            <template #card-icon>
              <div class="text-bb-red-error bg-bb-red-50 rounded-full p-1.5">
                <ic-warning :size="24" />
              </div>
            </template>
            <template #right-icon>
              <div class="text-bb-text-secondary">
                <ic-chevron
                  class="cursor-pointer"
                  direction="right"
                  :size="40"
                />
              </div>
            </template>
            <template #card-content>
              <div class="text-sm space-y-1">
                <h5 class="h5">{{ infringementSummary?.newCount ?? 0 }} new violations on your brand</h5>
                <p class="text-bb-text-secondary">Monitoring 1 campaign</p>
              </div>
            </template>
          </notification-card>
        </div>
      </div>
      <div class="w-1/2">
        <top-of-page-chart :competition-ranking="topOfPage" />
      </div>
    </div>

    <!--ROW 4-->
    <div class="flex gap-6 w-full h-100">
      <div class="w-full">
        <most-frequent-rank :most-frequent-rank="mostFrequentRank || {}" />
      </div>
    </div>

    <p class="text-xs text-bb-text-default pt-2">
      Monitoring since {{ createdAt }}
      <template v-if="pausedAt"> - Paused on {{ pausedAt }}</template>
    </p>
  </div>
</template>

<script>
// Components
import TopCompetitors from '@/views/site/search/competitor_monitoring_v2/overview/cards/TopCompetitors.vue'
import SummaryCard from '@/views/site/search/competitor_monitoring_v2/overview/cards/SummaryCard.vue'
import TopOfPageChart from '@/views/site/search/competitor_monitoring_v2/overview/cards/TopOfPageChart.vue'
import MostFrequentRank from '@/views/site/search/competitor_monitoring_v2/overview/cards/MostFrequentRank.vue'
import NotificationCard from '@/views/site/search/competitor_monitoring_v2/overview/cards/NotificationCard.vue'
import BrightbidLoader from '@/components/loader/BrightbidLoader.vue'
import BbBaseModal from '@/components/modals/brightbid/BbBaseModal.vue'
import SelectedMonitoringSettings from '../components/modals/SelectedMonitoringSettings.vue'

// Icons
import IcOneLineChart from '@/components/icon/brightbid/ic-one-line-chart.vue'
import IcTwoLineChart from '@/components/icon/brightbid/ic-two-line-chart.vue'
import IcNormalPeople from '@/components/icon/brightbid/ic-normal-people.vue'
import IcPositivePeople from '@/components/icon/brightbid/ic-positive-people.vue'
import IcNegativePeople from '@/components/icon/brightbid/ic-negative-people.vue'
import IcChevron from '@/components/icon/ic-chevron.vue'
import IcMagicWand from '@/components/icon/brightbid/ic-magic-wand.vue'
import IcWarning from '@/components/icon/brightbid/ic-warning.vue'
import IcClock from '@/components/icon/brightbid/IcClock.vue'
import IcDollar from '@/components/icon/ic-dollar.vue'
import IcSolidCog from '@/components/icon/brightbid/ic-solid-cog.vue'

import { mapActions, mapState, mapGetters } from 'vuex'
import dayjs from 'dayjs'

import { MONITORING_GROUP_TYPE } from '@/utils/enums'

export default {
  name: 'OverviewV2',
  components: {
    BrightbidLoader,
    TopOfPageChart,
    MostFrequentRank,
    TopCompetitors,
    SummaryCard,
    NotificationCard,
    BbBaseModal,
    SelectedMonitoringSettings,

    IcOneLineChart,
    IcTwoLineChart,
    IcNormalPeople,
    IcPositivePeople,
    IcNegativePeople,
    IcChevron,
    IcMagicWand,
    IcWarning,
    IcClock,
    IcDollar,
    IcSolidCog,
  },
  data() {
    return {
      MONITORING_GROUP_TYPE,
    }
  },
  computed: {
    ...mapState('site', ['selectedSite']),
    ...mapState('competitorMonitoringV2', [
      'selectedMonitoringGroup',
      'insights',
      'competitorSummary',
      'savingsInsights',
      'infringementSummary',
      'mostFrequentRank',
    ]),
    ...mapGetters('competitorMonitoringV2', ['top10CompetitorsByImpressionShare', 'competitorsByAbsoluteTopOfPage']),
    summaryData() {
      return [
        {
          label: 'Competitors',
          value: this.competitorSummary ? this.competitorSummary.totalCompetitors : '-',
          icon: 'ic-normal-people',
          iconClass: 'text-bb-brand-purple bg-bb-purple-50',
        },
        {
          label: 'New Entrants',
          value: this.competitorSummary ? this.competitorSummary.entered : '-',
          icon: 'ic-positive-people',
          iconClass: 'text-bb-red-error bg-bb-red-50 ml-2',
        },
        {
          label: 'Exits',
          value: this.competitorSummary ? this.competitorSummary.left : '-',
          icon: 'ic-negative-people',
          iconClass: 'text-bb-blue bg-bb-light-blue ml-2',
        },
      ]
    },
    myInsight() {
      if (!this.insights) return null
      return this.insights.find(insight => insight?.isOwn)
    },
    mySummary() {
      if (!this.insights)
        return [
          { label: 'Impressions share', value: '-' },
          { label: 'Abs. top of page', value: '-' },
          { label: 'Avg position', value: '-' },
        ]

      return [
        { label: 'Impressions share', value: this.myInsight ? `${this.myInsight.impressionShare}%` : '-' },
        { label: 'Abs. top of page', value: this.myInsight ? `${this.myInsight.absoluteTopOfPage}%` : '-' },
        { label: 'Avg position', value: this.myInsight ? `${this.myInsight.averagePosition}` : '-' },
      ]
    },
    totalHoursSaved() {
      if (!this.savingsInsights) return 0
      return parseFloat((this.savingsInsights.totalTimeSavedSeconds / 3600).toFixed(2))
    },
    totalMoneySaved() {
      if (!this.savingsInsights) return 0
      return `${this.savingsInsights.totalMoneySaved.value} ${this.savingsInsights.totalMoneySaved.currency}`
    },
    topOfPage() {
      if (!this.competitorsByAbsoluteTopOfPage) return []

      return this.competitorsByAbsoluteTopOfPage.map(competitor => {
        return {
          isOwn: competitor.isOwn,
          competitorName: competitor.competitorName,
          topPagePercentage: competitor.absoluteTopOfPage,
        }
      })
    },
    activatedSmartSavings() {
      return this.selectedMonitoringGroup?.keywords.filter(keyword => keyword.smartSavingActive).length
    },
    createdAt() {
      return dayjs(this.selectedMonitoringGroup?.createdAt).format('YYYY/MM/DD')
    },
    pausedAt() {
      if (!this.selectedMonitoringGroup?.initialized) {
        return dayjs(this.selectedMonitoringGroup?.updatedAt).format('YYYY/MM/DD')
      }
      return null
    },
  },
  methods: {
    ...mapActions('toast', ['loadToast']),
    ...mapActions('competitorMonitoringV2', ['setVisibleModal']),
    seeAllCompetitors() {
      this.$router.push({ name: 'all-competitors-v2' })
    },
    openSmartActivationModal() {
      this.setVisibleModal({
        type: 'selected-monitoring-settings',
        width: '800px',
        height: '720px',
        activeTab: 'smart-activation',
      })
    },
  },
}
</script>

<style scoped lang="scss">
.container-height {
  min-height: calc(100vh - 200px);
}

.purple-gradient {
  background: linear-gradient(103.71deg, #9496ff -20.18%, #6366fa 64.13%);
}
</style>
