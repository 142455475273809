<template>
  <div
    class="text-bb-text-default pl-8 pr-5 b pt-4 g-white w-full rounded-lg bb-default-shadow border border-neutral-50"
  >
    <div class="flex gap-2 items-center">
      <slot name="icon"></slot>
      <h4 class="h5 text-bb-text-default">{{ title }}</h4>
    </div>
    <div class="flex justify-between w-full p-4">
      <div
        v-for="(item, index) in summaryData"
        :key="index"
      >
        <div v-if="item.value !== null">
          <slot
            name="summary"
            :summary="item"
          />
        </div>

        <div v-else>
          <div
            class="h-14 bg-neutral-100"
            style="width: 1px"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SummaryCard',
  props: {
    title: {
      type: String,
      required: true,
    },
    summary: {
      type: Array,
      required: true,
    },
  },
  computed: {
    summaryData() {
      const result = []
      this.summary.forEach((item, index) => {
        result.push(item)
        if (index < this.summary.length - 1) {
          result.push({ value: null })
        }
      })
      return result
    },
  },
}
</script>

<style scoped lang="scss"></style>
